var mainConfig = {};
let myModal;

var bannerTemplate = `
<div class="modal fade" id="cookie" role="dialog" data-backdrop="static" data-keyboard="false"  data-bs-backdrop="static">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h4 class="modal-title">Willkommen beim Deutschen Ausschreibungsblatt, Vertriebspartner von Vergabe24 direkt.</h4>
        </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col">

                        <div class="cb_mainWrap">
                            <div class="cb_text">###message###</div>
                            <div class="cb_buttons clearfix"> <button data-dismiss="modal" onclick="cbAcceptAll()" class="btn btn--secondary cb_accept_all" style="width:100%">###button_text###</button><br> <small class="float-right"><a href="javascript:;"  onclick="cbConfig()" class="cb_config da_link" >Individuelle Einstellungen</a></small> </div>
                            <div class="cb_hidden_content">
                                <table>
                                    <tr>
                                        <td>
                                            <input type="checkbox" checked disabled> <label>Technisch notwendige Cookies</label><br> Diese Cookies sind für den Betrieb und den Zweck unserer Website unbedingt notwendig. Darunter fallen Cookies, die
                                            sicherheitsrelevante
                                            Funktionalitäten beinhalten. Ebenso können wir mit diesen Cookies erkennen, ob Sie eingeloggt sind. Sie können Ihren Browser so einstellen, dass diese Cookies blockiert oder Sie über diese Cookies
                                            benachrichtigt werden. Einige
                                            Bereiche der Website funktionieren dann aber möglicherweise nicht.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" class="cb_ck_stat" value="1" id="cb_ck_stat"> <label for="cb_ck_stat">Cookies zu statistischen Zwecken</label><br> Mit diesen Cookies können wir Besuche zählen und Zugriffsquellen
                                            eruieren, um die
                                            Leistung unserer Website zu ermitteln und zu verbessern. Alle von diesen Cookies erfassten Informationen werden aggregiert. Wenn Sie diese anonymisierten Cookies nicht zulassen, können wir unsere Website
                                            nicht an den Bedarf
                                            unserer Nutzer anpassen und entdecken Fehler auf der Website nicht.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input type="checkbox" class="cb_ck_mark" value="1" id="cb_ck_mark"> <label for="cb_ck_mark">Cookies für Marketingzwecke</label><br> Diese Cookies werden benutzt, um Ihre Nutzung der Website auszuwerten,
                                            um Reports über die
                                            Websiteaktivitäten zusammenzustellen und um weiteren mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen, insbesondere die Google Analytics-Werbefunktionen. Sie können
                                            verwendet werden, um
                                            ein Profil Ihrer Interessen zu erstellen. Sie funktionieren durch eindeutige Identifikation Ihres Browsers und Ihres Geräts. Wenn Sie diese Cookies nicht zulassen, werden Sie auf anderen Websites nicht
                                            unsere zielgerichtete
                                            Werbung erleben.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <br>
                                            <div><button data-dismiss="modal" onclick="cbAcceptAll()" class="btn btn--secondary cb_accept_all" style="width:100%">Alle akzeptieren</button><br><small class="float-right"><a href="javascript:;" data-dismiss="modal" onclick="cbSaveSelected()" class="cb_save_selected da_link">Auswahl speichern</a></small></div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
`;


function initialise(data) {
    mainConfig = data;

    if (getCookie("cb_ck_stat") == 1 || getCookie("cb_ck_mark") == 1) {

    } else {
        if (getCookie("cb_ck_tech") == 1) {

        } else {
            if (window.location.href.search("/datenschutz.php") < 0 && window.location.href.search("/impressum.php") < 0) {
                drawBanner();
            }

        }
    }
}

function drawBanner() {

    bannerTemplate = bannerTemplate.replace("###message###", mainConfig.content.message);
    bannerTemplate = bannerTemplate.replace("###button_text###", mainConfig.content.button_text);

    const e = document.querySelector('#cookie');

    if (e !== null) {
        e.parentElement.removeChild(e);
    }

    document.querySelector('body').insertAdjacentHTML("beforeend", bannerTemplate);

    myModal = new bootstrap.Modal(document.getElementById('cookie'));
    myModal.show()

    if (getCookie("cb_ck_mark") == 1) {
        document.querySelector(".cb_ck_mark").setAttribute("checked", true);

    }

    if (getCookie("cb_ck_stat") == 1) {
        document.querySelector(".cb_ck_stat").setAttribute("checked", true);

    }
}


function loadAnalytics() {
    //console.log("analytics wird geladen");
    (function(w, d, s, l, i) {
        console.log("googletagmanager");
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-W2Q446Q');

    (function(w, d, s, l, i) {
        console.log("googletagmanager");
        w[l] = w[l] || [];

        w[l].push({
            'js': new Date(),
            'config': i,
        });

        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s)
          //  dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'G-53DY5656CX');


}


(function() {
    var gc = getCookie("cb_ck_stat");
    if (gc == 1) {
        loadAnalytics();
    }

    initialise({
        "content": {
            "message": "Wir setzen immer Cookies, die für den Betrieb der Seite notwendig sind. Sind Sie damit einverstanden, dass wir darüber hinaus solche Cookies setzen, die lediglich zu anonymen Statistikzwecken, für Komforteinstellungen oder zur Anzeige personalisierter Inhalte genutzt werden? Bitte beachten Sie, dass auf Basis Ihrer Einstellungen womöglich nicht mehr alle Funktionalitäten der Seite zur Verfügung stehen. Ihre Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden. Mehr Informationen in unserer <a  class='da_link' href='/datenschutz.php'>Datenschutzerklärung</a>. <a class='da_link' href='/impressum.php'>Impressum</a>",
            "button_text": "Ich bin einverstanden",
        },
    });
})();


function cbSaveSelected(e) {
    var flag = 1;
    if (document.querySelector(".cb_ck_stat").checked) {
        setCookie("cb_ck_stat", 1, 3650);
        loadAnalytics();
    } else {
        flag = 0;
        eraseCookie("cb_ck_stat");
    }

    if (document.querySelector(".cb_ck_mark").checked) {
        setCookie("cb_ck_mark", 1, 3650);
    } else {
        flag = 0;
        eraseCookie("cb_ck_mark");
    }

    setCookie("cb_ck_tech", 1, 90);

    myModal.hide()
}

function cbAcceptAll(e) {
    setCookie("cb_ck_stat", 1, 3650);
    setCookie("cb_ck_mark", 1, 3650);
    loadAnalytics()
    myModal.hide()
}

function cbConfig(e) {
    document.querySelector(".cb_hidden_content").classList.toggle("show_hc");
}

/*
$(document).on("click", ".cb_close_content", function(e) {
    $(".cb_hidden_content").toggleClass("show_hc");
    $(".cb_close_content").toggle();
})*/

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value) + expires + "; path=/";
}

function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}
