//Init lozad(Lazyload)
const observer = lozad();
observer.observe();

main_Top_space();

function main_Top_space() {
  //Main top Spacing
  setTimeout(function () {
    var headerHeight = document.querySelector('.page-header').clientHeight;
    document.querySelector(".site-main").style.marginTop = headerHeight +'px';
  }, 200);
}

window.addEventListener("resize", main_Top_space);

// Select box label js
function SelectHasValue(select) {
  if(!select.value.length) {
    select.parentElement.classList.remove("value");
  } else {
    select.parentElement.classList.add("value");
    return false;
  }
}
const selects = document.querySelectorAll("select");
Array.from(selects).map((select) => {
  select.addEventListener('change', () => SelectHasValue(select));
  window.addEventListener('load', () => SelectHasValue(select));
});

// Go to Top JS
var goTop = document.querySelector(".goto-top");
if (goTop) {
  function scrollToTop() {

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  goTop.addEventListener("click", scrollToTop);
  window.addEventListener('scroll', () => {
    (window.scrollY > 100) ? goTop.classList.add('visible') : goTop.classList.remove('visible');
  });

  function myFunction() {
    body = document.body;
    html = document.documentElement;
    footerHeight = document.querySelector(".page-footer").clientHeight;
    documentHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    windowTop = document.querySelector("html").scrollTop;

    if(windowTop + window.outerHeight >= documentHeight) {
      document.querySelector(".goto-top").style.bottom = footerHeight + 1 + "px"
    } else {
      document.querySelector(".goto-top").style.bottom = "10px"
    }
  }
  window.addEventListener("scroll", myFunction);
  window.addEventListener("resize", myFunction);
}


function toForm(){

    var element = document.getElementById('form-target');
    window.scrollTo(0,element.offsetTop);
}





  // form.addEventListener('submit',

   function send(event) {
       const form = document.querySelector('#form');
     //  event.preventDefault();
     fields = document.querySelectorAll('[required]');

     let error = false;



    fields.forEach((item, i) => {
        //console.log(item.checkValidity());
        if (!item.checkValidity()) {

            if (!error) {
               item.scrollIntoView({ behavior: 'instant', block: 'center' });
            }

            error = true
            if (item.name == "dse") {
                document.querySelector('.cbl').classList.add("valid_error");
                item.closest(".form-group").querySelector('.error_info').classList.add("d-inline-block");
            }else{
                item.classList.add("parsley-error");
                item.parentNode.querySelector('.error_info').classList.add("d-inline-block");
            }

        }else{
            item.classList.remove("parsley-error");

            if (item.name == "dse") {
                document.querySelector('.cbl').classList.remove("valid_error");
                item.closest(".form-group").querySelector('.error_info').classList.remove("d-inline-block");
            }else{
                item.parentNode.querySelector('.error_info').classList.remove("d-inline-block");
            }

        }


    });

    if (error) {
        return;
    }

    //item Dieses Feld muss ausgefüllt werden!
//console.log("hier");

//console.log(form, new FormData(form));
       const result = document.querySelector('#form-result');
       const action = form.getAttribute('action');
       const method = form.getAttribute('method');

       fetch(action, {
           method: method,
           body: new FormData(form)
       })
       .then(function(response) {
           if (response.ok) {
               document.querySelector('#form_wrap').style.display = "none";
               document.querySelector('#form_response').style.display = "block";

              // return response.text();
           } else {
               //return response.json();
           }
       })
       .then(function(output) {
           if (result) {
              // result.innerHTML = output;
           }
       })
       .catch(function(error) {
           if (result) {
             //  result.innerHTML = 'Error: ' + error;
           }

           throw new Error(error);
       });
   };



(function() {
})();
